
// Libraries
import { Vue, Component, Prop } from "nuxt-property-decorator";
import { ButtonText } from "~/operations/messages";

// Components

@Component({
  components: {
    // load the components asynchronously when showExtendedContent is met
    SwarmChart: () => import("~/components/organisms/chart/SwarmChart.vue"),
    PriceSystemChart: () =>
      import("~/components/organisms/chart/PriceSystemChart.vue"),
  },
})
export default class CombinedRecommendationCharts extends Vue {
  @Prop() presetZipCode: string;

  ButtonText = ButtonText;

  async mounted() {
    await this.$store.dispatch("schwarmometer/fetchUserSignal");
    await this.$store.dispatch(
      "schwarmometer/fetchEsyoilSignal",
      this.tryReturnZipCodeByLocalStorage(),
    );
  }

  showExtendedContent = {
    swarm: false,
    recommendation: false,
  };

  toggleRelatedContent(target: "swarm" | "recommendation") {
    if (target === "swarm") {
      this.showExtendedContent.recommendation = false;
      this.showExtendedContent.swarm = !this.showExtendedContent.swarm;

      if (this.$store.state.schwarmometer.swarmData.priceData.length === 0) {
        this.$store.dispatch("schwarmometer/fetchSwarmData");
      }
    } else if (target === "recommendation") {
      this.showExtendedContent.swarm = false;
      this.showExtendedContent.recommendation =
        !this.showExtendedContent.recommendation;

      // Proceed with data fetching if necessary
      const zipcode =
        localStorage.getItem("userZipCode") &&
        localStorage.getItem("userZipCode").length > 4
          ? localStorage.getItem("userZipCode")
          : "72070";
      if (
        this.$store.state.schwarmometer.esyoilSignalData.priceData.length === 0
      ) {
        this.$store.dispatch("schwarmometer/fetchPriceSystemData", zipcode);
      }
    }
  }

  tryReturnZipCodeByLocalStorage() {
    if (this.presetZipCode && this.presetZipCode.length > 4)
      return this.presetZipCode;
    let storageZipCode = localStorage.getItem("userZipCode");
    if (storageZipCode !== null && storageZipCode.length > 4)
      return storageZipCode;
    return "";
  }

  returnSwarmDirectionIcon(buyingIntesionIndex) {
    if (buyingIntesionIndex === null) return "question-circle-solid.svg";
    if (buyingIntesionIndex === 1) return "meh-solid.svg";
    if (buyingIntesionIndex === 2) return "meh-solid.svg";
    if (buyingIntesionIndex === 3) return "meh-solid.svg";
    if (buyingIntesionIndex === 4) return "smile-solid.svg";
    if (buyingIntesionIndex === 5) return "smile-solid.svg";
  }

  returnPriceSystemDirectionIcon(buyingIntesionIndex) {
    if (buyingIntesionIndex === null) return "question-circle-solid.svg";
    return buyingIntesionIndex
      ? "thumbs-up-solid.svg"
      : "thumbs-down-solid.svg";
  }
}


// Components
import { Component, Prop, Vue } from "nuxt-property-decorator";
import EnergyLegend from "~/components/organisms/display/EnergyLegend.vue";
import HeatingStatistics from "~/components/organisms/display/HeatingStatistics.vue";
import HeatingOilTable from "~/components/organisms/table/HeatingOilTable.vue";
import Footnotes from "~/components/organisms/text/Footnotes.vue";

import { OilRequirements } from "~/types/OilRequirement";

@Component({
  components: {
    HeatingOilTable,
    Footnotes,
    EnergyLegend,
    HeatingStatistics,
  },
})
export default class HeatingOilCalculator extends Vue {
  @Prop() city: string;
  @Prop() zipcode: string;
  @Prop() oilRequirements: OilRequirements;
}

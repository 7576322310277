
// Components
import { Component, Vue } from "nuxt-property-decorator";
import HeatingStatistics from "~/components/organisms/display/HeatingStatistics.vue";
import HeatingOilTable from "~/components/organisms/table/HeatingOilTable.vue";
import Footnotes from "~/components/organisms/text/Footnotes.vue";

import { LegendItem } from "~/types/OilRequirement";

@Component({
  components: {
    HeatingOilTable,
    Footnotes,
    HeatingStatistics,
  },
})
export default class EnergyLegend extends Vue {
  legendItems: LegendItem[] = [
    {
      class: "A+",
      description: "Effizienzhaus 40, Passivhaus, Nullenergiehaus",
    },
    { class: "A", description: "Effizienzhaus 55, 3-Liter-Haus" },
    { class: "B", description: "Effizienzhaus 70" },
    { class: "C", description: "Effizienzhaus 85, Effizienzhaus 100" },
    {
      class: "D",
      description:
        "Einfamilienhäuser und hochwertig sanierte Bestandsimmobilien mit einem Energiebedarf zwischen 100 bis 130 kWh/m² und Jahr",
    },
    {
      class: "E",
      description:
        "Ältere Einfamilienhäuser, die nach den Mindestanforderungen der Energieeinsparverordnung (EnEV) gebaut wurden",
    },
    {
      class: "F",
      description:
        "Ältere Einfamilienhäuser, die noch nicht energetisch saniert sind",
    },
    {
      class: "G",
      description:
        "Nur teilweise modernisierte Altbauten, die nach der 1. Wärmeschutzverordnung von 1977 gebaut wurden",
    },
    {
      class: "H",
      description:
        "Unsanierte und schlecht gedämmte Häuser, oft denkmalgeschützte Gebäude",
    },
  ];
}


// Libraries
import dayjs from "dayjs";
import { Vue, Component, Prop } from "nuxt-property-decorator";

// Components
import BlogPostAuthor from "~/components/molecules/display/BlogPostAuthor.vue";
import BlogPostExcerpt from "~/components/molecules/text/BlogPostExcerpt.vue";

// Mixins
import { returnAuthorDataByID } from "~/mixins/mixins";

@Component({
  components: {
    BlogPostAuthor,
    BlogPostExcerpt,
  },
})
export default class BlogPost extends Vue {
  @Prop() blogPostContent: {
    author: number;
    id: string;
    title: { rendered: string };
    date: string;
    modified: string;
    content: object;
    additionalParameters: { date };
    excerpt: object;
    featuredImageUrl: object;
    link: string;
    status: string;
    _links: Array<[]>;
  };
  @Prop({ default: false }) hideBlogPostAuthorTile: boolean;
  @Prop({ default: false }) displayExcerptDate: boolean;
  @Prop({ default: "default" }) variant: string;

  returnAuthorDataByID = returnAuthorDataByID;

  get blogDate() {
    return `${this.blogPostContent.additionalParameters?.date?.postDate}`;
  }

  tryExcerptDate() {
    if (this.displayExcerptDate)
      return `${this.blogPostContent.additionalParameters.date.dd}. ${this.blogPostContent.additionalParameters.date.mmmm} ${this.blogPostContent.additionalParameters.date.yyyy}`;
    return "";
  }
}

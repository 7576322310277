
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { HeatingDay } from "~/types/OilRequirement";

import { formatNumberToString } from "~/utils/oilRequirementUtils";

@Component({
  components: {},
})
export default class TemperatureCard extends Vue {
  @Prop() temperatures: HeatingDay[];
  @Prop() minTemp: number;
  @Prop() maxTemp: number;
  @Prop() title: string;

  formatNumberToString = formatNumberToString;

  calculateHeight(temperature) {
    return ((temperature - this.minTemp) / (this.maxTemp - this.minTemp)) * 100;
  }
}

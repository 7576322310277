
// Libraries
import { Vue, Component, Prop } from "nuxt-property-decorator";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";

// Mixins
import { removeParagraphTag } from "~/mixins/formatting";

@Component({
  components: {
    RouterLink,
    LoadingSpinner,
  },
  methods: {
    removeParagraphTag,
  },
})
export default class BlogPostExcerptMolecule extends Vue {
  @Prop() title: string;
  @Prop() date: string;
  @Prop() excerpt: object;
  @Prop() blogPostSlug: object;
  @Prop() blogPostID: string;
  @Prop() blogDate: string;
  @Prop({
    type: Object,
    default() {
      return { name: "esyoil" };
    },
  })
  authorData: { name: string };
}

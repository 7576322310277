
// Components
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { HeatingDay } from "~/types/OilRequirement";

@Component({
  components: {},
})
export default class TemperatureCard extends Vue {
  @Prop() heatingDays: HeatingDay[];
  @Prop() maxHeatingDays: number;
  @Prop() title: string;

  calculateWidth(days) {
    return (days / this.maxHeatingDays) * 100;
  }
}

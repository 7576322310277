
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const OilpriceModule = namespace("oilprice");
const FederalStatesModule = namespace("federalstates");

interface ResultItem {
  class: string;
  value: number;
  unit: string;
  period: string;
}

@Component
export default class HeatingOilConsumption extends Vue {
  @Prop({ type: String, required: true }) readonly city!: string;
  @Prop({ type: String, required: true }) readonly zipcode!: string;

  @OilpriceModule.Action("fetchConsumptionOilRequirement")
  fetchConsumptionOilRequirement!: (params: any) => Promise<void>;

  @FederalStatesModule.State("cityPriceHistory")
  cityPriceHistory!: any;

  @OilpriceModule.State("consumptionOilRequirement")
  consumptionOilRequirement!: {
    literRequirement: number;
    annualPrice: number;
    monthlyPrice: number;
  };

  selectedEnergyClass: number | null = null;
  squareMeters = 100;
  showErrorMessage = false;

  energyClassConsumption = [
    { value: 1.5, name: "A+" },
    { value: 3, name: "A" },
    { value: 4, name: "B" },
    { value: 7.5, name: "C" },
    { value: 11.5, name: "D" },
    { value: 14.5, name: "E" },
    { value: 18, name: "F" },
    { value: 22.5, name: "G" },
    { value: 30, name: "H" },
  ];

  get hasResults(): boolean {
    return (
      this.selectedEnergyClass !== null &&
      this.squareMeters !== null &&
      this.consumptionOilRequirement.literRequirement !== 0
    );
  }

  get resultItems(): ResultItem[] {
    return [
      {
        class: "primary",
        value: this.consumptionOilRequirement.literRequirement,
        unit: " Liter",
        period: "/ Jahr",
      },
      {
        class: "secondary",
        value: this.consumptionOilRequirement.annualPrice,
        unit: " €",
        period: "/ Jahr",
      },
      {
        class: "tertiary",
        value: this.consumptionOilRequirement.monthlyPrice,
        unit: " €",
        period: "/ Monat",
      },
    ];
  }

  handleEnergyClassChange(value: string): void {
    this.selectedEnergyClass = Number(value);
    this.showErrorMessage = false;
  }

  handleSquareMeterChange(value: string): void {
    this.squareMeters = Number(value);
  }

  calculateOilConsumption(): void {
    if (!this.selectedEnergyClass) {
      this.showErrorMessage = true;
      return;
    }
    this.calculateResults();
  }

  async calculateResults(): Promise<void> {
    if (this.selectedEnergyClass && this.squareMeters) {
      await this.fetchConsumptionOilRequirement({
        zipcode: this.zipcode,
        oilPrice:
          this.cityPriceHistory.chart.data[
            this.cityPriceHistory.chart.data.length - 1
          ].y,
        energyClass: this.selectedEnergyClass,
        squareMeter: this.squareMeters,
      });
    }
  }
}
